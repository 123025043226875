import { useState } from "react";
import "./App.css";
import {
  Grid,
  Box,
  TextField,
  Button,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Autocomplete,
  Tooltip,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { filter, head, orderBy, trim } from "lodash";
import { Add, Delete, Help, Restore } from "@mui/icons-material";
import { useNames } from "./useNames";

interface IRunner {
  id: string;
  time: string;
  timeInSeconds: number;
  name: string;
  editing?: boolean;
}

function App() {
  const [runners, setRunners] = useState<IRunner[]>([]);
  const { names } = useNames();

  const setRunnersAndSave = (runners: IRunner[]) => {
    setRunners(runners);
    if (runners.length) {
      window.localStorage.setItem("runners", JSON.stringify(runners));
    }
  };

  const restoreRunnersFromStorage = () => {
    try {
      const lsRunners = window.localStorage.getItem("runners");
      if (lsRunners !== null) {
        const runners = JSON.parse(lsRunners);
        setRunners(runners);
      }
    } catch (e) {
      console.error("Chyba při parsování");
    }
  };

  const removeRunner = (id: string) =>
    setRunnersAndSave(filter(runners, (runner: IRunner) => runner.id !== id));

  const setRunnerEdited = (id: string) => {
    setRunnersAndSave(
      runners.map((runner) => ({
        ...runner,
        editing: runner.id === id ? true : false,
      }))
    );
  };

  const updateRunner = (id: string, newName: string) => {
    setRunnersAndSave(
      runners.map((runner) => ({
        ...runner,
        editing: false,
        name: runner.id === id ? newName : runner.name,
      }))
    );
  };

  const sortedRunners = orderBy(runners, "timeInSeconds", "asc");
  const bestTimeInSeconds = head(sortedRunners)?.timeInSeconds;

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    const time = data.time as string;

    if (time !== "00:00:00") {
      const [hours, minutes, seconds] = time.split(":");
      setRunnersAndSave([
        ...runners,
        {
          id: btoa(new Date().getTime().toString()),
          time: time as string,
          timeInSeconds:
            Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds),
          name: (data.name as string) || `Závodník ${runners.length + 1}`,
          editing: false,
        },
      ]);
    }
  };

  const computePoints = (timeInSeconds: number) => {
    return ((1 - (timeInSeconds / bestTimeInSeconds! - 1)) * 70).toFixed(2);
  };

  const computeDifferenceInPercent = (timeInSeconds: number): number => {
    return ((timeInSeconds / bestTimeInSeconds! - 1) * 100).toFixed(
      2
    ) as unknown as number;
  };

  // useEffect(() => {
  //   document.addEventListener("paste", (e) => {
  //     e.preventDefault();
  //     const cb = e.clipboardData;
  //     if (cb !== null) {
  //       const clipText = cb.getData("text/plain");

  //       console.debug(e, e.clipboardData, clipText);

  //       const lines = clipText.split("\r\n");
  //       const splittedFile = lines.map((line) => line.split("\t"));
  //       console.debug(splittedFile);

  //       // let clipRows = clipText.split("\n");
  //       // let output = {};
  //       // for (let i = 0; i < clipRows.length; i++) {
  //       //   clipRows[i] = clipRows[i].split("\t");
  //       // }
  //       // var jsonObj = [];
  //       // for (i = 0; i < clipRows.length - 1; i++) {
  //       //   var item = {};
  //       //   for (j = 0; j < clipRows[i].length; j++) {
  //       //     if (clipRows[i][j] != "\r") {
  //       //       if (clipRows[i][j].length !== 0) {
  //       //         item[j] = clipRows[i][j];
  //       //       }
  //       //     }
  //       //   }
  //       //   jsonObj.push(item);
  //       // }
  //     }
  //   });
  // }, []);

  const [helpDialogVisible, setHelpDialogVisible] = useState(false);

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Velká cena vytrvalců - výpočet bodů ze závodu
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => setHelpDialogVisible(true)}
          >
            <Help />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Dialog
        open={helpDialogVisible}
        onClose={() => setHelpDialogVisible(false)}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>Nápověda</Typography>
          <Typography>Verze: 0.9</Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Typography sx={{ fontSize: 15 }}>
            Jedná se o neoficiální aplikaci pro výpočet bodů ze závodů Velké
            ceny vytrvalců Olomouckého kraje, kterou pořádá{" "}
            <a href="https://liga100.cz" target="_blank" rel="noreferrer">
              Liga 100
            </a>
            . Ačkoliv byl výpočet zpětně kontrolován na vícero závodech, je bez
            jakékoliv záruky a nenahrazuje oficiální výsledky, které jsou
            pravidelně zveřejňovány na webových stránkách{" "}
            <a
              href="https://www.liga100.cz/velka-cena-vytrvalcu/"
              target="_blank"
              rel="noreferrer"
            >
              Velké ceny vytrvalců
            </a>
            .
          </Typography>
          <Typography sx={{ mt: 1, fontSize: 15 }}>
            Pro přidání závodníka vyplňte jeho čas. Jméno je možné vybrat ze
            seznamu dřívějších účastníků některého ze závodů (našeptávač Vám
            bude nabízet závodníky podle toho, jaký text zadáte). Případně je
            možné zadat úplně nové jméno, pokud se závodník předchozích závodů
            neúčastnil. Jméno je nepovinné, v takovém připadě se automaticky
            vyplní „Závodník 1“, „Závodník 2“ apod.
          </Typography>
          <Typography sx={{ mt: 1, fontSize: 15 }}>
            Po vyplnění času a jména stiskněte klávesu [ENTER] nebo tlačítko
            přidat. Přidaní závodníci jsou automaticky řazeni od nejlepšího a
            jsou jim spočítány body. Pro správný výpočet je nutné přidat prvního
            závodníka a poté jakékoliv další závodníky z dané věkové kategorie.
          </Typography>
          <Typography sx={{ mt: 1, fontSize: 15 }}>
            Všichni vyplnění závodníci jsou automaticky ukládání do úložiště.
            Pokud si budete chtít výsledky později znovu vyvolat, stačí
            stisknout tlačítko „Obnovit dříve vyplněné závodníky“{" "}
            <Restore sx={{ verticalAlign: "middle" }} />.
          </Typography>
          <Typography sx={{ mt: 1, fontSize: 15 }}>
            <strong>Autor aplikace</strong>: Michal Kobelka &lt;
            <a href="mailto:michalkobelka@gmail.com">michalkobelka@gmail.com</a>
            &gt;, nedočkavý běžec, který potřeboval znát body hned po závodě.
            Běhám každý den, o čemž píšu na{" "}
            <a
              href="https://kobelka.cz/category/sport/beh/"
              target="_blank"
              rel="noreferrer"
            >
              svém blogu
            </a>
            .
          </Typography>
        </DialogContent>
      </Dialog>
      <Box sx={{ p: 2, mt: 1 }}>
        <form onSubmit={handleFormSubmit}>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12} sm={6} md={4} lg={2}>
              <TextField
                id="time"
                label="Čas"
                variant="outlined"
                fullWidth={true}
                size="small"
                name="time"
                type="time"
                inputProps={{ step: 1 }}
                defaultValue="00:00:00"
                onPaste={(event) => {
                  let paste = trim(event.clipboardData.getData("text"));
                  // Add leading zero
                  if (paste.length === 7) {
                    paste = `0${paste}`;
                  }
                  // Add leading hours
                  if (paste.length === 5) {
                    paste = `00:${paste}`;
                  }
                  (document.getElementById("time") as HTMLInputElement).value =
                    paste;
                }}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={2}>
              {/* <TextField
              label="Jméno"
              variant="outlined"
              fullWidth={true}
              size="small"
              name="name"
              inputProps={{ list: "names" }}
            /> */}
              <Autocomplete
                id="name"
                freeSolo={true}
                options={names}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="name"
                    label="Jméno"
                    size="small"
                  />
                )}
              />
              {/* <datalist id="names">
              {names.map((name) => (
                <option key={name} value={name} />
              ))}
            </datalist> */}
            </Grid>
            <Grid item={true}>
              <Button
                startIcon={<Add />}
                variant="contained"
                size="large"
                type="submit"
              >
                Přidat
              </Button>
              <Tooltip title="Smazat všechny závodníky">
                <IconButton
                  onClick={() => setRunnersAndSave([])}
                  sx={{ ml: 2 }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obnovit dříve vyplněné závodníky">
                <IconButton
                  onClick={() => restoreRunnersFromStorage()}
                  sx={{ ml: 1 }}
                >
                  <Restore />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item={true} xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </form>

        <Box sx={{ overflowX: "auto", mt: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap" }}></TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>Pořadí</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>Jméno</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                  Čas
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                  Čas v sekundách
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                  Ztráta na vítěze (%)
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                  Body
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRunners.map((runner, index) => {
                const differenceInPercent = computeDifferenceInPercent(
                  runner.timeInSeconds
                );
                const percentOfWinner = 100 - differenceInPercent;
                const points = computePoints(runner.timeInSeconds);
                return (
                  <TableRow key={runner.id}>
                    <TableCell width={20}>
                      <IconButton
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() => removeRunner(runner.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Box onClick={() => setRunnerEdited(runner.id)}>
                        {runner.editing ? (
                          <TextField
                            size="small"
                            defaultValue={runner.name}
                            onKeyUp={(event) => {
                              if (
                                event.key === "Enter" ||
                                event.keyCode === 13
                              ) {
                                const newValue = (event.target as any).value;
                                updateRunner(runner.id, newValue);
                              }
                            }}
                          />
                        ) : (
                          <Tooltip title="Upravit jméno">
                            <Box sx={{ cursor: "pointer" }}>{runner.name}</Box>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="right">{runner.time}</TableCell>
                    <TableCell align="right">{runner.timeInSeconds}</TableCell>
                    <TableCell align="right">{differenceInPercent}</TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={`${(percentOfWinner / 100).toFixed(
                          3
                        )} * 70 = ${points}`}
                      >
                        <Box sx={{ cursor: "help" }}>{points}</Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
}

export default App;
